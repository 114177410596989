import { Button, Form, Modal } from "antd";
import React, { useState } from "react";
import { VendorOtpVerify } from "../../ApiService/ApiFun";
import { makeLabel, setLocalStorageItem } from "../../Utils";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

const OtpModal = ({ open, setOpen, detail }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate=useNavigate()
  const handleVerify = (val) => {
    setBtnLoading(true);
    const payload = {
      verificationToken: detail.token,
      mobile_number: detail.mobile,
      otp: val.otp,
    };
    VendorOtpVerify(payload)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("accessToken",res.data.access_token)
          setLocalStorageItem("userDetail",res.data.user)
          navigate("/dashboard")

        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
      <div>
        <Form onFinish={(val) => handleVerify(val)}>
          <Form.Item
            name={"otp"}
            rules={[
              {
                required: true,
                message: "Authentication Code Is Required",
              },
            ]}
            labelCol={{ span: 24 }}
            label={makeLabel("Enter OTP")}
          >
            <OTPInput
              //   value={otp}
              //   onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputStyle={{
                width: "80%",
                marginRight: "10px",
                height: "40px",
                border: "2px solid var(--primary)",
              }}
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={btnLoading}
              type="primary"
              iconPosition="end"
              htmlType="submit"
              className="font-poppins bg-primary  w-full mt-4  text-white font-bold rounded-none bg-secondary"
            >
              Verify Code
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default OtpModal;
