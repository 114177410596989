import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Images } from "../ImageController/Index";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getLocalStorageItem, setLocalStorageItem } from "../Utils";
import { getProfile, getRoles } from "../ApiService/ApiFun";
import ChangePassDrawer from "./ChangePassDrawer";
import ProfileDrawer from "./ProfileDrawer";
import { IoCreateSharp } from "react-icons/io5";

const ProjectLayout = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { access, role } = getLocalStorageItem("userDetail");
  const [profilePic, setProfilePic] = useState(null);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const [openchangePassDrawer, setChangePassDrawer] = useState(false);
  const [submenus, setSubMenus] = useState([
    {
      name: "Dashboard",
      acess: "dashboard",
      to: "/dashboard",
    },
    {
      name: "Sales",
      acess: "sales",
      to: "/sales",
    },
    {
      name: "Shop",
      acess: "shops",

      to: "/shops",
    },
    {
      name: "Product",
      acess: "product",

      to: "/products",
    },
    {
      name: "Requests",
      acess: "requests",

      to: "/requests",
    },
    {
      name: "Customer",
      acess: "customer",
      to: "/customers",
    },
    {
      name: "Employee",
      acess: "employee",
      to: "/employee",
    },
  ]);
  const getAccess = () => {
    getRoles()
      .then((res) => {
        if (res.status === "success") {
          let data = { ...getLocalStorageItem("userDetail") };
          data = { ...data, access: res.data.map((i) => i?.roleType) };
          setLocalStorageItem("userDetail", data);
        }
      })
      .catch((err) => console.log(err));
  };
  const getProfileDetail = () => {
    getProfile()
      .then((res) => {
        if (res.data.image_path !== null) {
          setProfilePic(res.data.image_path);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    if (!openProfileDrawer) {
      getProfileDetail();
    }
  }, [openProfileDrawer]);
  useEffect(() => {
    getAccess();
  }, [location]);
  return (
    <>
      <div className="fixed w-full h-[125px] z-[99] ">
        <div className="flex justify-between bg-primary p-4 items-center">
          <NavLink to={"/dashboard"}>
            <p className="font-poppins text-white text-2xl cursor-pointer">
              Inventory
            </p>
          </NavLink>
          <div className="flex justify-center items-center gap-x-3">
            <div className="text-end">
              <p
                onClick={() => setChangePassDrawer(true)}
                className="text-white font-inter font-semibold text-xs cursor-pointer hover:text-orange transition-all select-none"
              >
                Change Password
              </p>
              <p
                onClick={() => {
                  localStorage.clear();
                  navigate("/");
                }}
                className="text-white font-inter font-semibold text-xs cursor-pointer hover:text-orange transition-all mt-2 select-none"
              >
                Logout
              </p>
            </div>
            <LazyLoadImage
              effect="blur"
              onClick={() => setOpenProfileDrawer(true)}
              src={
                profilePic
                  ? "https://api.theinfomed.com" + profilePic
                  : Images.Avatar
              }
              alt="profile"
              className="w-12 h-12 cursor-pointer rounded-full"
            />
          </div>
        </div>
        <div className="flex overflow-x-scroll justify-start px-4 shadow-lg text-xs md:text-sm font-inter shadow-gray-200 h-10 items-center gap-x-7 bg-white">
          {submenus.map((i, index) => (
            <NavLink
              className={`${
                role === "employee"
                  ? access.includes(i.acess.toLowerCase()) ||
                    i.acess === "dashboard" ||
                    i.acess === "sales"
                    ? "block"
                    : "hidden"
                  : role === "vendor"
                  ? i.acess === "product"
                    ? "hidden"
                    : "block"
                  : "block"
              }`}
              to={i.to}
            >
              {" "}
              <p
                className={`${
                  location.pathname.includes(i.name.toLowerCase())
                    ? "text-primary font-semibold underline underline-offset-4"
                    : "text-black"
                } cursor-pointer `}
                key={index}
              >
                {i.name}
              </p>
            </NavLink>
          ))}
        </div>
      </div>

      <div className=" bg-gray-100 pt-[126px] pb-4 h-screen min-h-screen overflow-y-scroll  max-h-screen">
        {((role == "employee" &&
          access.includes("billing") &&
          location.pathname !== "/billing") ||
          (role == "vendor" && location.pathname !== "/billing")) && (
          <div className="flex mx-8 justify-end items-center  pt-2">
            <NavLink to={"/billing"}>
              <p className="bg-primary flex justify-end items-center cursor-pointer gap-x-3 px-4 py-2 font-inter font-semibold text-white">
                <IoCreateSharp /> Create Bill
              </p>
            </NavLink>
          </div>
        )}
        <div className="mx-8 pt-2">{children}</div>
      </div>
      {openchangePassDrawer && (
        <ChangePassDrawer
          open={openchangePassDrawer}
          setOpen={setChangePassDrawer}
        />
      )}
      {openProfileDrawer && (
        <ProfileDrawer
          open={openProfileDrawer}
          setopen={setOpenProfileDrawer}
        />
      )}
    </>
  );
};

export default ProjectLayout;
