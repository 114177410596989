import { Input } from "antd";
import React from "react";

const CommonInput = ({
  placeHolder = "",
  name = "",
  prefix=null,
  value = "",
  disabled=false,
  type="text",
  onInput = () => {},
  onChange = () => {},
  className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300" ,
}) => {
  return (
    <div className="w-full">
      <Input 
      prefix={prefix}
      type={type}
        name={name}
        disabled={disabled}
        placeholder={placeHolder}
        value={value}
        onInput={onInput}
        onChange={onChange}
        className={className}
      />
    </div>
  );
};

export default CommonInput;
