// import React, { Suspense, useState } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

// import { Images } from "../../ImageController/Index";
// import { Button, Form, Input, Spin } from "antd";
// import { makeLabel, setLocalStorageItem } from "../../Utils";
// import CommonInput from "../../Common/CommonInput";
// import { FaArrowRight } from "react-icons/fa";
// import { NavLink, useNavigate } from "react-router-dom";
// import { VendorLogin } from "../../ApiService/ApiFun";

// const Login = () => {
// const [btnLoading, setBtnLoading] = useState(false);
// const navigate = useNavigate();
// const handleLogin = (val) => {
//   setBtnLoading(true);
//   VendorLogin(val)
//     .then((res) => {
//       if (res.status === "success") {
//         setLocalStorageItem("accessToken", res.data.access_token);
//         setLocalStorageItem("userDetail", res.data.user);
//         navigate("/dashboard");
//       }
//     })
//     .catch((err) => console.log(err))
//     .finally(() => setBtnLoading(false));
// };
//   return (
//     <Suspense
//       fallback={
//         <div className="flex justify-center items-center h-screen">
//           <Spin spinning={true}></Spin>
//         </div>
//       }
//     >
//       <div className="">
//         <div className="grid grid-cols-2 place-items-center ">
//           <div className="w-full  bg-authBg h-screen hidden md:flex justify-center items-center ">
//             <LazyLoadImage
//               effect="blur"
//               src={Images.Signup}
//               alt="signupimage"
//               // className="w-[70%]"
//             />
//           </div>
//           <div className="w-full  h-screen flex justify-center items-center col-span-2 md:col-span-1">
//             <div className="w-3/4 m-auto">
//               <div className="font-poppins text-center text-xl font-bold">
//                 <p>Sign in to your account</p>
//               </div>
//               <Form onFinish={(val) => handleLogin(val)}>
// <Form.Item
//   name={"loginId"}
//   rules={[
//     {
//       required: true,
//       message: "Username or email Is Required",
//     },
//   ]}
//   labelCol={{ span: 24 }}
//   label={makeLabel("Email/Mobile")}
// >
//   <CommonInput placeHolder="Mobile or email address..." />
// </Form.Item>
// <Form.Item
//   name={"password"}
//   rules={[
//     {
//       required: true,
//       message: "Password Is Required",
//     },
//   ]}
//   labelCol={{ span: 24 }}
//   label={makeLabel("Password")}
// >
//   <Input.Password
//     className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
//     placeholder="Password"
//   />
// </Form.Item>

//                 <div className="flex justify-end items-center">
//                   <Button
//                     loading={btnLoading}
//                     type="primary"
//                     iconPosition="end"
//                     icon={<FaArrowRight />}
//                     htmlType="submit"
//                     style={{ color: "white" }}
//                     prefix="jj"
//                     className="font-poppins text-white font-bold rounded-none bg-primary"
//                   >
//                     Sign In
//                   </Button>
//                 </div>
//               </Form>
//               <NavLink to={"/register"}>
//                 <p className="text-gray1 mt-3 cursor-pointer text-sm font-poppins text-center">
//                   Don't have an account? Sign Up
//                 </p>
//               </NavLink>
//             </div>
//           </div>
//         </div>
//       </div>
//     </Suspense>
//   );
// };

// export default Login;

import React, { useState } from "react";
import bgImage from "../../Assets/loginbg.png";
import { Button, Form, Input } from "antd";
import CommonInput from "../../Common/CommonInput";
import { VendorLogin } from "../../ApiService/ApiFun";

import { makeLabel, setLocalStorageItem } from "../../Utils";
import { NavLink, useNavigate } from "react-router-dom";
import { FaRegUser } from "react-icons/fa";
import { RiLockPasswordFill } from "react-icons/ri";

const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const handleLogin = (val) => {
    setBtnLoading(true);
    VendorLogin(val)
      .then((res) => {
        if (res.status === "success") {
          setLocalStorageItem("accessToken", res.data.access_token);
          setLocalStorageItem("userDetail", res.data.user);
          navigate("/dashboard");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <>
      <div
        className="flex justify-center md:justify-end items-center min-h-screen md:px-32 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className="w-96 bg-[#ffffff29] relative z-10 login-wrapper  bg-cover bg-center mx-4 shadow-[0_0_12px_-5px_#2b2a2a] backdrop-blur-sm p-8 rounded-lg"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <section className="font-inter">
            <div>
              <Form
                onFinish={(val) => handleLogin(val)}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name={"loginId"}
                  rules={[
                    {
                      required: true,
                      message: "Username or email Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Email/Mobile", "white")}
                >
                  <CommonInput
                    prefix={<FaRegUser />}
                    placeHolder="Mobile or email address..."
                  />
                </Form.Item>
                <Form.Item
                  name={"password"}
                  rules={[
                    {
                      required: true,
                      message: "Password Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Password", "white")}
                >
                  <Input.Password
                    prefix={<RiLockPasswordFill />}
                    className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item className="mt-8">
                  <Button
                    className="bg-red-700 mb-2 rounded-none font-semibold font-inter"
                    block="true"
                    loading={btnLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    Log In
                  </Button>
                </Form.Item>
                <div className="mb-20 font-inter">
                  <p className="text-white flex justify-start items-center gap-2">
                    Don't have an account?{" "}
                    <NavLink to="/register">
                      <p className="text-white font-inter">Sign Up?</p>
                    </NavLink>
                  </p>{" "}
                </div>
              </Form>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Login;
