import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorageItem } from "../Utils";
import ErrorBoundary from "./ErrorBoundry";
function PublicAuth(props) {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };

  return isAuthenticated() ? (
    <>
      <Navigate to="/dashboard" />
    </>
  ) : (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}
export default PublicAuth;
