import React, { Suspense } from "react";
import { Navigate, Outlet } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundry";
import { getLocalStorageItem } from "../Utils";
import ProjectLayout from "../ProjectLayout";
import { Skeleton } from "antd";

function PrivateAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };

  return isAuthenticated() ? (
    <>
      <ProjectLayout>
        <ErrorBoundary>
          <Suspense
            fallback={
              <>
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
                <Skeleton active className="mt-4" />
              </>
            }
          >
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </ProjectLayout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default PrivateAuth;
