import axios from "axios";
import { dispatchtoast, removeLocalStorageItem, showError } from "../../Utils";

// const cookies = new Cookies();
// const jwtToken = cookies.get("jwt_token");
export const APIURL = "https://api.theinfomed.com/api/v1/";
// export const APIURL = "http://192.168.1.37:3008/api/v1/";

const ApiService = axios.create({
  // withCredentials: true,
  baseURL: APIURL,
});

// Add a request interceptor
ApiService.interceptors.request.use((config) => {
  return config;
});
// Add a response interceptor
ApiService.interceptors.response.use(
  (response) => {
    if (response.data.status) {
      return response.data;
    } else if (response.data.details) {
      showError(response.data.details[0]?.validation_results);
    } else {
      dispatchtoast(response.data.message ?? response.data.error, true);
    }
    return response.data;
  },
  (error) => {
    // Handle errors if needed
    const { status } = error?.response;
    if (status === 401) {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.msg,
        true
      );
      removeLocalStorageItem("isLoggedIn");
      removeLocalStorageItem("accessToken");
      // window.location.href = "/";
    }
    if (status && status >= 400 && status < 500) {
      if (error?.response.data.details) {
        showError(error?.response.data.details[0]?.validation_results);
      } else {
        dispatchtoast(
          error?.response.data.message ?? error?.response.data.error,
          true
        );
      }
    } else if (status && status >= 500) {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.msg,
        true
      );
    }
    return Promise.reject(error);
  }
);

export default ApiService;
