import { Button, Drawer, Form, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../ImageController/Index";
import { MdModeEdit } from "react-icons/md";
import CommonInput from "../Common/CommonInput";
import { dispatchtoast, emailPattern, mobilePattern } from "../Utils";
import { getProfile, updateprofile } from "../ApiService/ApiFun";

const ProfileDrawer = ({ open, setopen }) => {
  const fileInputRef = useRef(null);
  const [form] = Form.useForm();
  const [profilePic, setProfilePic] = useState(null);
  const [spinner, setSpinner] = useState(false);
  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = () => {
    setSpinner(true);
    const formdata = new FormData();
    const file = fileInputRef.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        console.log(reader.result);
      };
      reader.readAsDataURL(file);
      formdata.append("image", file);
      updateprofile(formdata)
        .then((response) => {
          if (response.status) {
            getProfileDetail();
            dispatchtoast("Image Updated Successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setSpinner(false));
    }
  };
  const getProfileDetail = () => {
    setSpinner(true);
    getProfile()
      .then((res) => {
        form.setFieldsValue({
          name: res.data.name,
          email: res.data.email,
          mobile: res.data.mobile_number,
        });
        if (res.data.image_path !== null) {
          setProfilePic(res.data.image_path);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  useEffect(() => {
    getProfileDetail();
    //eslint-disable-next-line
  }, []);
  const handleFinish = (val) => {
    setSpinner(true);
    updateprofile(val)
      .then((res) => {
        if (res.status) {
          getProfileDetail();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  return (
    <>
      <Drawer
        closeIcon={<p className="text-xs font-inter text-white">X</p>}
        headerStyle={{ background: "var(--primary)" }}
        title={<p className="font-inter text-white font-semibold">Profile</p>}
        onClose={() => setopen(false)}
        open={open}
      >
        <Spin spinning={spinner}>
          <div className="flex relative  justify-center w-36 m-auto h-36 items-center">
            <LazyLoadImage
              onError={(e) => {
                e.target.src = Images.Avatar;
                e.target.alt = "userpic";
              }}
              src={
                profilePic
                  ? "https://api.theinfomed.com" + profilePic
                  : Images.Avatar
              }
              alt="userpic"
              //   crossOrigin="anonymous"
              className="rounded-full object-contain shadow-md shadow-gray-500 w-36 h-36"
            />
            <div
              onClick={handleClick}
              className="absolute bottom-0 right-0 bg-primary cursor-pointer text-white rounded-full p-2"
            >
              <input
                type="file"
                accept="image/*"
                onChange={() => {
                  handleImageChange();
                  fileInputRef.current.value = "";
                }}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
              <MdModeEdit />
            </div>
          </div>
          <Form onFinish={handleFinish} form={form} className="mt-4">
            <div className="grid grid-cols-2 place-items-center gap-x-3">
              <Form.Item labelCol={{ span: 24 }} name={"name"} label="Name">
                <CommonInput placeHolder="Enter Name" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    pattern: emailPattern,
                    message: "Enter valid email",
                  },
                ]}
                labelCol={{ span: 24 }}
                name={"email"}
                label="Email"
              >
                <CommonInput placeHolder="Enter Email" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    pattern: mobilePattern,
                    message: "Enter valid mobile",
                  },
                ]}
                labelCol={{ span: 24 }}
                name={"mobile"}
                label="Mobile"
              >
                <CommonInput placeHolder="Enter Mobile" />
              </Form.Item>
            </div>
            <Form.Item className=" w-full ">
              <Button
                htmlType="submit"
                type="primary"
                className="text-white w-full bg-primary rounded-none border-none"
              >
                Change
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default ProfileDrawer;
