// import React, { Suspense, useState } from "react";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

// import { Images } from "../../ImageController/Index";

// import { Button, Form, Input, Spin } from "antd";
// import {
//   dispatchtoast,
//   emailPattern,
//   makeLabel,
// } from "../../Utils";
// import CommonInput from "../../Common/CommonInput";
// import { FaArrowRight } from "react-icons/fa";
// import { NavLink } from "react-router-dom";
// import { VendorRegister } from "../../ApiService/ApiFun";
// import OtpModal from "./OtpModal";

// const SignUp = () => {
// const [btnLoading, setBtnLoading] = useState(false);
// const [form] = Form.useForm();
// const [detail, setDetail] = useState(null);
// const [openOtpModal, setOpenOtpModal] = useState(false);
// const handleRegister = (val) => {
//   setBtnLoading(true);
//   let data = { ...val };
//   delete data["confirmPassword"];
//   VendorRegister(data)
//     .then((res) => {
//       if (res.status) {
//         setDetail({
//           token: res.data.verification_token,
//           mobile: val.mobile_number,
//         });
//         setOpenOtpModal(true);
//         dispatchtoast(res.message);
//       }
//     })
//     .catch((err) => console.log(err))
//     .finally(() => setBtnLoading(false));
// };
//   return (
//     <Suspense
//       fallback={
//         <div className="flex justify-center items-center h-screen">
//           <Spin spinning={true}></Spin>
//         </div>
//       }
//     >
//       <div className="">
//         <div className="grid grid-cols-2 place-items-center ">
//           <div className="w-full h-screen bg-authBg hidden md:flex justify-center items-center ">
//             <LazyLoadImage
//             effect="blur"
//               src={Images.Signup}
//               alt="signupimage"
//             />
//           </div>
//           <div className="w-full flex justify-center items-center h-screen col-span-2 md:col-span-1">
//             <div className="w-3/4  m-auto">
//               <div className="font-poppins text-center text-xl font-bold">
//                 <p>Create your account</p>
//               </div>
//               <Form form={form} onFinish={(val) => handleRegister(val)}>
// <Form.Item
//   name={"name"}
//   rules={[
//     {
//       required: true,
//       message: " Name Is Required",
//     },
//   ]}
//   labelCol={{ span: 24 }}
//   label={makeLabel(" Name")}
// >
//   <CommonInput placeHolder="First Name" />
// </Form.Item>

// <Form.Item
//   rules={[
//     {
//       required: true,
//       message: "User Name Is Required.",
//     },
//   ]}
//   name={"mobile_number"}
//   labelCol={{ span: 24 }}
//   label={makeLabel("Mobile Number")}
// >
//   <CommonInput placeHolder="Mobile Number" />
// </Form.Item>
// <Form.Item
//   name={"email"}
//   rules={[
//     {
//       required: true,
//       message: "Email Is Required",
//     },
//     {
//       pattern: emailPattern,
//       message: "Please Enter Valid Email",
//     },
//   ]}
//   labelCol={{ span: 24 }}
//   label={makeLabel("Email")}
// >
//   <CommonInput placeHolder="Email" />
// </Form.Item>
// <div className="grid grid-cols-2 place-items-start gap-x-4">
//   <Form.Item
//     name={"password"}
//     rules={[
//       {
//         required: true,
//         message: "Password Is Required",
//       },
//     ]}
//     labelCol={{ span: 24 }}
//     label={makeLabel("Password")}
//   >
//     <Input.Password
//       autoComplete={false}
//       placeholder={"Password"}
//       className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
//     />
//   </Form.Item>{" "}
//   <Form.Item
//     name="confirmPassword"
//     // dependencies={["password"]}
//     rules={[
//       {
//         required: true,
//         message: "Confirm password is required",
//       },
//       ({ getFieldValue }) => ({
//         validator(_, value) {
//           if (!value || getFieldValue("password") === value) {
//             return Promise.resolve();
//           }
//           return Promise.reject(
//             new Error("The passwords do not match!")
//           );
//         },
//       }),
//     ]}
//     labelCol={{ span: 24 }}
//     label={makeLabel("Confirm Password")}
//   >
//     <Input.Password
//       placeholder={"Confirm Password"}
//       className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
//     />
//   </Form.Item>
// </div>
// <div className="flex justify-end items-center">
//   <Button
//     loading={btnLoading}
//     icon={<FaArrowRight />}
//     iconPosition="end"
//     type="primary"
//     htmlType="submit"
//     style={{ color: "white" }}
//     prefix="jj"
//     className="font-poppins text-white font-bold rounded-none bg-primary"
//   >
//     Create Account
//   </Button>
// </div>
//               </Form>
//               <NavLink to={"/"}>
//                 <p className="text-gray1 mt-3 cursor-pointer text-sm font-poppins text-center">
//                   Already have an account? sign in
//                 </p>
//               </NavLink>
//             </div>
//           </div>
//         </div>
//       </div>
// {openOtpModal && (
//   <OtpModal
//     open={openOtpModal}
//     setOpen={setOpenOtpModal}
//     detail={detail}
//   />
// )}
//     </Suspense>
//   );
// };

// export default SignUp;

import React, { useState } from "react";
import bgImage from "../../Assets/loginbg.png";
import { Button, Form, Input } from "antd";
import CommonInput from "../../Common/CommonInput";
import { VendorRegister } from "../../ApiService/ApiFun";

import { dispatchtoast, emailPattern, makeLabel } from "../../Utils";
import { NavLink } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import OtpModal from "./OtpModal";
const Login = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [detail, setDetail] = useState(null);
  const [openOtpModal, setOpenOtpModal] = useState(false);
  const handleRegister = (val) => {
    setBtnLoading(true);
    let data = { ...val };
    delete data["confirmPassword"];
    VendorRegister(data)
      .then((res) => {
        if (res.status) {
          setDetail({
            token: res.data.verification_token,
            mobile: val.mobile_number,
          });
          setOpenOtpModal(true);
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };

  return (
    <>
      <div
        className="flex justify-center md:justify-end items-center min-h-screen md:px-32 bg-cover bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div
          className=" bg-[#ffffff29] relative z-10 login-wrapper  bg-cover bg-center mx-4 shadow-[0_0_12px_-5px_#2b2a2a] backdrop-blur-sm p-8 rounded-lg"
          style={{ backgroundImage: `url(${bgImage})` }}
        >
          <section className="font-inter">
            <div>
              <Form
                onFinish={(val) => handleRegister(val)}
                layout="vertical"
                requiredMark="optional"
              >
                <Form.Item
                  name={"name"}
                  rules={[
                    {
                      required: true,
                      message: " Name Is Required",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel(" Name", "white")}
                >
                  <CommonInput placeHolder="First Name" />
                </Form.Item>

                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "User Name Is Required.",
                    },
                  ]}
                  name={"mobile_number"}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Mobile Number", "white")}
                >
                  <CommonInput placeHolder="Mobile Number" />
                </Form.Item>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Email Is Required",
                    },
                    {
                      pattern: emailPattern,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Email", "white")}
                >
                  <CommonInput placeHolder="Email" />
                </Form.Item>
                <div className="grid grid-cols-2 place-items-start gap-x-4">
                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Password Is Required",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label={makeLabel("Password", "white")}
                  >
                    <Input.Password
                      autoComplete={false}
                      placeholder={"Password"}
                      className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name="confirmPassword"
                    // dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Confirm password is required",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("The passwords do not match!")
                          );
                        },
                      }),
                    ]}
                    labelCol={{ span: 24 }}
                    label={makeLabel("Confirm Password", "white")}
                  >
                    <Input.Password
                      placeholder={"Confirm Password"}
                      className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    />
                  </Form.Item>
                </div>
                <div className="flex justify-end mt-2 items-center">
                  <Button
                    loading={btnLoading}
                    icon={<FaArrowRight />}
                    iconPosition="end"
                    type="primary"
                    htmlType="submit"
                    style={{ color: "white" }}
                    prefix="jj"
                    className="font-poppins text-white font-bold rounded-none bg-red-700"
                  >
                    Create Account
                  </Button>
                </div>
                <div className="mb-20 mt-10 font-inter">
                  <p className="text-white flex justify-start items-center gap-2">
                    Already have an acount?{" "}
                    <NavLink to="/">
                      <p className="text-white font-inter">Sign in?</p>
                    </NavLink>
                  </p>{" "}
                </div>
              </Form>
            </div>
          </section>
        </div>
      </div>
      {openOtpModal && (
        <OtpModal
          open={openOtpModal}
          setOpen={setOpenOtpModal}
          detail={detail}
        />
      )}
    </>
  );
};

export default Login;
