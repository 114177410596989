import React from 'react'
import { IoReload } from 'react-icons/io5';
import { Images } from '../ImageController/Index';

const NotFound = () => {
  return (
    <>
       <div className="flex flex-col justify-center items-center gap-y-3 font-poppins">
        <img
          loading="lazy"
          src={Images.manThinking}
          className="w-[400px]"
          alt="manthinking"
        />
        <p className="font-semibold text-lg text-primary">
          Something Went wrong
        </p>
        <p className='text-primary font-semibold'><span className='underline underline-offset-4'>Troubleshoot:</span> <span>Kindly logout your account then login......</span></p>
        <button
          onClick={() => {
            window.location.reload();
          }}
          className="bg-primary px-4 py-2 rounded-md text-white font-semibold flex justify-center items-center gap-x-3 "
        >
          <IoReload /> Reload the page
        </button>
      </div>
    </>
  )
}

export default NotFound