import { Button, Drawer, Form } from "antd";
import React, { useState } from "react";
import CommonInput from "../Common/CommonInput";
import { dispatchtoast, makeLabel } from "../Utils";
import { FaArrowRight } from "react-icons/fa6";
import { VendorChangePassword } from "../ApiService/ApiFun";
import { IoMdClose } from "react-icons/io";

const ChangePassDrawer = ({ open, setOpen }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const handleChange = (val) => {
    setBtnLoading(true);
    VendorChangePassword(val)
      .then((res) => {
        if (res.status === "success") {
          setOpen(false)
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <Drawer
      closeIcon={<IoMdClose color="white" />}
      headerStyle={{ background: "var(--primary)" }}
      title={<p className="font-poppins text-white">Change Password</p>}
      open={open}
      onClose={() => setOpen(false)}
    >
      <Form onFinish={(val) => handleChange(val)}>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter old password",
            },
          ]}
          labelCol={{ span: 24 }}
          label={makeLabel("Old Password")}
          name={"oldPassword"}
        >
          <CommonInput placeHolder="Enter Old password" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter password",
            },
          ]}
          labelCol={{ span: 24 }}
          label={makeLabel("Password")}
          name={"password"}
        >
          <CommonInput placeHolder="Enter password" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please enter confirm password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The passwords do not match!"));
              },
            }),
          ]}
          labelCol={{ span: 24 }}
          label={makeLabel("Confirm Password")}
          name={"confirmPassword"}
        >
          <CommonInput placeHolder="Enter confirm password" />
        </Form.Item>
        <Form.Item>
          <div className="flex justify-end items-center">
            <Button
              loading={btnLoading}
              type="primary"
              iconPosition="end"
              icon={<FaArrowRight />}
              htmlType="submit"
              style={{ color: "white" }}
              className="font-poppins text-white font-bold rounded-none bg-primary"
            >
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default ChangePassDrawer;
