import Signup from "../Assets/Signup.png";
import Login from "../Assets/Login.png";
import Google from "../Assets/google.png";
import facebook from "../Assets/facebook.png";
import Logo from "../Assets/Logo.png";
import FullLogo from "../Assets/FullLogo.png";
import Hamburger from "../Assets/menu.png";
import CourseImage from "../Assets/Course Images.png";
import Graph from "../Assets/Graph.png";
import OrderSuccess from "../Assets/Success.png";
import OrderFailed from "../Assets/failed.png";
import OrderPending from "../Assets/pending.png";

import Avatar from "../Assets/Avatar.png";
import ChatIllus from "../Assets/ChatIllus.png";
import DummyImage from "../Assets/dummyuser.png";
import EmptyCart from "../Assets/Emptycart.png";
import Nodata from "../Assets/Nodata.png";
import manThinking from "../Assets/man-thinking.png";
import PlanValidityExpire from "../Assets/planvalidity.png";

export const Images = {
  Signup,
  Login,
  Google,
  facebook,
  Logo,
  FullLogo,
  Hamburger,
  CourseImage,
  Graph,
  OrderSuccess,
  OrderFailed,
  OrderPending,
  Avatar,
  ChatIllus,
  DummyImage,
  EmptyCart,
  Nodata,
  manThinking,
  PlanValidityExpire,
};
